
.activeClass {
    background: #4d5aff;
    color: #ffffff;
}

.company-contain {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);

    .head-box {
        display: flex;
        justify-content: space-between;
        height: 40px;
        padding: 30px 30px 0 40px;

        .title {
            margin-top: 10px;
        }
    }

    .divide {
        padding: 0 20px;
    }

    .table-box {
        display: flex;
        flex-direction: column;
        height: 1%;
        flex: 1;

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        .form-box {
            width: 100%;
            flex: 1;
            height: 1%;
            position: relative;
            padding-left: 70px;

            .el-input {
                width: 330px;
            }

            .footer-box {
                justify-content: center;
                display: flex;
                padding: 220px 0 50px;

                ::v-deep .el-button {
                    width: 100px;
                    height: 32px;
                    line-height: 9px;
                }

                ::v-deep .el-button--primary {
                    background: #1E33E4;
                }

                ::v-deep .el-button+.el-button {
                    margin-left: 20px;
                }
            }
        }

        .upload-box {
            display: flex;

            .upload-main {
                width: 200px;
                height: 200px;
                cursor: pointer;
                background: #F1F1F1;
                color: #4D5AFF;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                i {
                    font-size: 36px;
                }
            }

            .showImg-box {
                margin-left: 20px;

                &:nth-child(1) {
                    margin-left: 0px;
                }

                .logo-box {
                    width: 100px;
                    height: 100px;
                    border: 1px solid #EEEEEE;
                    border-radius: 2px;
                }

                .qa-box {
                    max-width: 154px;
                    max-height: 212px;
                }
            }

            .img-box {
                margin-left: 24px;
                position: relative;

                .cover-box {
                    width: 200px;
                    height: 200px;
                }

                .el-icon-close {
                    position: absolute;
                    top: 0;
                    right: -10px;
                    cursor: pointer;
                }
            }
        }
    }

    .filter-box {
        .box1 {
            padding: 0 20px 10px 20px;
            height: 40px;
            display: flex;

            span {
                margin-top: 8px;
                line-height: 32px;
                display: inline-block;
                width: 80px;
                height: 32px;
                text-align: center;
                cursor: pointer;
            }
        }
    }

    .imggeshi {
        position: absolute;
        left: 120px;
        top: -50px;
        color: #999999;
        font-size: 12px;
    }

    .addimg {
        display: block;
        width: 22px;
        height: 22px;
        background: #1d33e3;
        color: #ffffff;
        border-radius: 50%;
        position: absolute;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        left: 30%;
        top: -80%;
    }
}
